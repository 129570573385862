<template>
  <div
    class="information"
    :class="{
      'information--full-width': fullwidth,
    }"
  >
    <div v-if="getInfo && getInfo.title" class="title" v-html="getInfo.title"></div>
    <div
      v-if="getInfo && getInfo.body"
      ref="content"
      class="templates-content"
      v-html="infoBody"
    ></div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { auth, information, room } from "@/store/modules/store.namespaces";
import { GET_INFO_SECTION } from "@/store/modules/information/action-types";
import useComponentTemplates from "@/mixins/templates/useComponentTemplates";

export default {
  name: "Information",
  mixins: [useComponentTemplates],
  data() {
    return {
      fullwidth: false,
    };
  },
  computed: {
    ...mapState(room, ["roomNumber"]),
    ...mapState(information, {
      getInfo: state => state.information || {},
    }),
    ...mapState(auth, ["user"]),
    infoBody() {
      return this.getInfo.body.replace(/(<img(.*?))(style="(.*?)")?(>)/g, "$1$5");
    },
  },
  async created() {
    await this.setInfoSection(this.$route.params.sectionId);
    this.initTemplates();
    this.handleGlobalModifiers();
    this.handleDto();
    this.handleFrameHtml();
  },
  methods: {
    ...mapActions(information, {
      setInfoSection: GET_INFO_SECTION,
    }),
    handleDto() {
      const dtoEl = this.$el.querySelector("[data-dto]");
      if (dtoEl) {
        const { dto } = dtoEl.dataset;
        const iframe = this.$el.querySelector("iframe");
        const dtoHash = dto.split("|").reduce((accum, key) => {
          if (key in this) {
            return `${accum}${key}=${JSON.stringify(this[key])};`;
          }
          return accum;
        }, "#");
        iframe.src = `${iframe.src}${dtoHash}`;
      }
    },
    handleGlobalModifiers() {
      const globalModifiersEl = this.$el.querySelector("[data-global-modifiers]");
      if (globalModifiersEl) {
        const { globalModifiers } = globalModifiersEl.dataset;
        globalModifiers.split("|").forEach(modifier => {
          if (modifier in this) {
            this[modifier] = true;
          }
        });
      }
    },
    handleFrameHtml() {
      const frameHtml = this.$el.querySelector("iframe[data-html]");
      if (frameHtml) {
        frameHtml.contentWindow.document.write(
          `<html><body>${frameHtml.dataset.html}</body></html>`,
        );
      }
    },
    // TODO удалить после мероприятия Эббот январь 2021
    abbotDone() {
      this.$notify({
        group: "roomSystem",
        type: "success",
        text: "Ваш голос учтен",
        duration: 5000,
      });
    },
  },
};
</script>

<style lang="less">
@import "~@/styles/components/_templates";

.information {
  width: 100%;
  max-width: 560px;
  padding: 40px 20px;
  margin: 0 auto;

  &--full-width {
    max-width: initial;
    margin: 0;
  }
}

@media (max-width: 760px) {
  .information {
    padding-top: 34px;
  }
}

/* TODO удалить после мероприятия Эббот январь 2021 */
.additionAbbot {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
}
</style>
